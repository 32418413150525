<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item :to="{ name: 'DashboardHome' }"><i class="fa fa-home"></i></b-breadcrumb-item>
            <b-breadcrumb-item :to="{ name: 'Branches' }">分店管理</b-breadcrumb-item>
            <b-breadcrumb-item active>設定分店照片</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <div class="row mb-2 align-items-end">
        <div class="col-8 d-flex items-center" style="flex-direction: column">
          <h4 class="font-weight-bold">設定分店照片</h4>
        </div>
      </div>

      <div class="mb-4" v-if="branch != null">
        {{ branch.name }}
      </div>

      <VueDraggable class="list-unstyled s-image-list mb-5" handle=".s-draggable" tag="ul" v-model="images"
        :move="checkMove">
        <li class="s-image-list-item s-draggable" :key="i" v-for="(image, i) in images">
          <img class="s-cropped-image" :src="image.url" />

          <!-- 發現手機板chrome 的click 似乎與draggable 處理event 衝突, 先簡單用touchstart 解掉 -->
          <button class="btn btn-danger s-delete-btn" type="button" v-show="canClick" @click="deleteImage(i)"
            @touchstart="deleteImage(i)">
            Ｘ
          </button>
        </li>
        <li class="s-image-list-item" slot="footer" v-if="canUpload">
          <ImageSelector @input="addImage($event)">
            <template #trigger="{ openFileSelector }">
              <button class="btn s-trigger-btn" :disabled="!canClick" @click="openFileSelector">
                <template v-if="canClick">
                  <div class="add-image-icon">＋</div>
                  <div>新增</div>
                </template>

                <template v-else> 上傳中... </template>
              </button>
            </template>
          </ImageSelector>
        </li>
      </VueDraggable>
      <div class="d-flex justify-content-center">
        <b-button variant="success" :disabled="!canClick" @click="gotoBranchList">回分店列表</b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import VueDraggable from "vuedraggable";
import ImageSelector from "@/components/ImageSelector";
import branchApi from "@/apis/branch";
import imageApi from "@/apis/image";
import _ from "lodash"


export default {
  components: { VueDraggable, ImageSelector },
  data: () => ({
    currentBranchID: null,
    branchID: null,
    branch: null,
    images: [],
    branchOptions: null,
    canClick: true,
    canUpload: true,
  }),
  computed: {
    ...mapState("general", {
      currentBranch: (state) => state.currentBranch,
    }),
  },
  created: function () {
    this.currentBranchID = this.$route.params.branchID;
    this.getBranchDetail(this.currentBranchID, true);
  },
  watch: {
    async images() {
      if (_.isEqual(this.images, this.branch.images)) {
        return;
      }
      try {
        this.canClick = false;
        await branchApi.updateBranchImages(this.currentBranchID, this.images);
        await this.getBranchDetail(this.currentBranchID);
      } catch (error) {
        this.$swal.fire({
          title: "錯誤",
          type: "error",
          text: "更新分店圖片失敗",
        });
      } finally {
        this.canClick = true;
      }
    },
  },
  methods: {
    // 上傳中的時候，不要讓他作任何動作，不可移動照片
    checkMove() {
      return this.canClick;
    },
    async addImage(imageBase64) {
      try {
        this.canClick = false;
        const { data } = await imageApi.uploadByBase64WithoutStoreToDb(
          imageBase64
        );

        this.images.push({ ...data.image, sort: this.images.length + 1 });
      } catch (err) {
        this.$swal.fire({
          title: "錯誤",
          type: "error",
          text: "上傳圖片失敗",
        });
      } finally {
        this.canClick = true
      }
    },
    async getBranchDetail(id) {
      const { data } = await branchApi.getBranch(id);
      this.branch = data.data;
      this.images = [...this.branch.images];
    },
    deleteImage(index) {
      this.images.splice(index, 1);
    },
    gotoBranchList() {
      this.$router.push({
        name: "Branches"
      });
    },
  },
};
</script>

<style scoped>
.s-image-list {
  margin: -0.75rem;
  display: flex;
  flex-wrap: wrap;
}

.s-image-list-item {
  position: relative;
  width: calc((100% / 3) - 0.75rem * 2);
  border: 1px solid #ddd;
  margin: 0.5rem 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.s-cropped-image {
  width: 100%;
  height: 100%;
}

.s-delete-btn {
  position: absolute;
  top: -0.75rem;
  right: -0.75rem;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.s-image-selector {
  width: 100%;
  height: 100%;
}

.s-trigger-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.add-image-icon {
  font-weight: 800;
  margin-bottom: 8px;
}
</style>
